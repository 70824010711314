import {
  CLEAR_USERS_REDUCER,
  FETCH_NOTIFICATIONS,
  SET_METADATA,
  SET_USER,
} from "./constants";
import { combineReducers } from "redux";
import { Metadata, PwgNotification, User } from "../../models/users";
import {
  createReducerForKeyedArray,
  createReducerWithTaskName,
  createSimpleReducer,
  INITIAL_STATE_EMPTY_TASK,
  TaskState,
} from "../../utils/reducers";

const defaultUser: User = {
  user_id: "",
  zoho_contact_id: "",
  confirmed: false,

  first_name: "",
  last_name: "",
  phone_number: "",
  email: "m",
  date_of_birth: "",
  city: "",
  country: "",
  state: "",
  tos_pp_signed_date: 0,
  date_last_updated: 0,
  gender: "",
  health_concerns: "",
  marital_status: "",
  children: "",
  quality_of_life: "",
  referral_sources: [],
  referral_source_other: "",
  asip_status: "not_started",
  prep_fq_profile_id: "",
  primary_asip_profile_id: "",
  prep_fq_status: "not_started",
  asip_profiles_count: 1,

  learning_module_priorities: [],
  has_learning_module_priorities: false,
  sent_priorities_intro_email: false,
  completed_priorities_onboarding: false,

  notification_settings: {
    disable_all: false,
    priorities: true,
    priorities_frequency: 7,
    flfs: true,
  },
};

const defaultMetadata: Metadata = {
  is_admin: false,
  is_partner: false,
  is_premium: false,
  is_rct: false,
};

const exampleNotifications: PwgNotification[] = [
  // {
  //   date_created: new Date().getTime(),
  //   description: "This is a very long description of a notification. Hi Hello Bye",
  //   is_seen: false,
  //   notification_id: "notifcation_id_1",
  //   title: "Notification 1",
  //   type: "reminder",
  //   user_id: "user_id",
  //   link: "https://planwellguide.com/news/"
  // },
  // {
  //   date_created: new Date().getTime(),
  //   description: "This is a very long description of a notification. Hi Hello Bye",
  //   is_seen: false,
  //   notification_id: "notifcation_id_2",
  //   title: "Notification 2",
  //   type: "reminder",
  //   user_id: "user_id",
  //   link: "https://planwellguide.com/news/"
  // },
  // {
  //   date_created: new Date().getTime(),
  //   description: "This is a very long description of a notification. Hi Hello Bye",
  //   is_seen: false,
  //   notification_id: "notifcation_id_3",
  //   title: "Notification 3",
  //   type: "reminder",
  //   user_id: "user_id",
  //   link: "https://planwellguide.com/news/"
  // },
  // {
  //   date_created: new Date().getTime(),
  //   description: "This is a very long description of a notification. Hi Hello Bye",
  //   is_seen: true,
  //   notification_id: "notifcation_id_4",
  //   title: "Notification 4",
  //   type: "reminder",
  //   user_id: "user_id",
  //   link: "https://planwellguide.com/news/"
  // },
  // {
  //   date_created: new Date().getTime(),
  //   description: "This is a very long description of a notification. Hi Hello Bye",
  //   is_seen: true,
  //   notification_id: "notifcation_id_5",
  //   title: "Notification 5",
  //   type: "reminder",
  //   user_id: "user_id",
  //   link: "https://planwellguide.com/news/"
  // },
];

export const USERS_INITIAL_STATE: {
  user: User;
  loadUser: TaskState;
  metadata: Metadata;
  notifications: PwgNotification[];
  loadNotifications: TaskState;
} = {
  user: {
    ...defaultUser,
  },
  loadUser: { ...INITIAL_STATE_EMPTY_TASK, loading: true },
  metadata: {
    ...defaultMetadata,
  },
  notifications: [...exampleNotifications],
  loadNotifications: INITIAL_STATE_EMPTY_TASK,
};

const usersRootReducer = combineReducers({
  user: createSimpleReducer(SET_USER, USERS_INITIAL_STATE.user),
  loadUser: createReducerWithTaskName(SET_USER, USERS_INITIAL_STATE.loadUser),
  metadata: createSimpleReducer(SET_METADATA, USERS_INITIAL_STATE.metadata),
  notifications: createReducerForKeyedArray(
    FETCH_NOTIFICATIONS,
    USERS_INITIAL_STATE.notifications
  ),
  loadNotifications: createReducerWithTaskName(
    FETCH_NOTIFICATIONS,
    USERS_INITIAL_STATE.loadNotifications
  ),
});

const usersReducer = (state: any, action: any) => {
  if (action.type === CLEAR_USERS_REDUCER) {
    state = {
      ...USERS_INITIAL_STATE,
    };
  }
  return usersRootReducer(state, action);
};

export default usersReducer;
