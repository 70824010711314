import { doc, getDoc, getFirestore } from "firebase/firestore";
import useSWR from "swr";
import { Metadata } from "../models/users";
import { firebaseApp } from "./firebaseClient";

const fetcher = async (url: string) => {
  if (url) {
    const splits = url.split(`/users/metadata/`);
    const user_id = splits[1];
    const firestore = getFirestore(firebaseApp);
    const userMetaSnap = await getDoc(
      doc(firestore, `users`, user_id, `private`, `metadata`)
    );
    return userMetaSnap.data();
  } else {
    return undefined;
  }
};

function useUserMetadata(user_id?: string) {
  const { data, mutate, error } = useSWR(
    [user_id ? `/users/metadata/${user_id}` : null],
    fetcher
  );
  const result: {
    userMetadata: Metadata;
    isLoading: boolean;
    isError: boolean | string;
    mutate: any;
  } = {
    userMetadata: data as any,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
  return result;
}

export default useUserMetadata;
