import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  AUTH,
  DEAR_DR_PDF,
  DEAR_SDM_PDF,
  LOGIN,
  PREP_FQ_PDF,
  ASIP_REMINDER,
} from "../constants/routes";
import { routerPushAndScroll } from "../utils/router";
import AuthContext from "./AuthContext";
import useUser from "./useUser";
import useUserMetadata from "./useUserMetadata";
import logging from "../../src/utils/logging";
import { PwgError } from "../models/common";
import { getAuth, onIdTokenChanged, User } from "firebase/auth";
import { firebaseApp } from "./firebaseClient";

const pagesToIgnore = [PREP_FQ_PDF, ASIP_REMINDER];
const pathsToIgnore = [AUTH, DEAR_DR_PDF, DEAR_SDM_PDF];
function AuthProvider({ children }: any) {
  const router = useRouter();
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [entryPage, setEntryPage] = useState("");
  const { mutate } = useUser(authUser?.uid);
  const {} = useUserMetadata(authUser?.uid);
  useEffect(() => {
    const auth = getAuth(firebaseApp);
    const unsubscribe = onIdTokenChanged(auth, async (authUser: User) => {
      try {
        if (!authUser) {
          setAuthUser(null);
          await mutate();
          if (
            pagesToIgnore.every((page) => router.pathname !== page) &&
            pathsToIgnore.every((page) => !router.pathname.includes(page))
          ) {
            setEntryPage(router.asPath);
            routerPushAndScroll(router, LOGIN);
          }
          return;
        } else {
          setAuthUser(authUser);
          await mutate();
          if (!authUser.emailVerified) {
            if (
              pagesToIgnore.every((page) => router.pathname !== page) &&
              pathsToIgnore.every((page) => !router.pathname.includes(page))
            ) {
              routerPushAndScroll(router, LOGIN);
            }
          }
          return;
        }
      } catch (error) {
        const pwgError: PwgError = {
          user_id: authUser.uid,
          ssr: false,
          context: "AuthProvider",
          date_created: new Date().getTime(),
          error: error?.toString(),
          pathname: router.asPath,
        };
        await logging.e(
          "🚀 ~ file: AuthProvider.tsx ~ line 58 ~ error",
          error,
          pwgError
        );
      }
    });
    return () => unsubscribe();
  }, [authUser, mutate, router.pathname, router]);
  return (
    <AuthContext.Provider value={{ user: authUser, entry_page: entryPage }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
