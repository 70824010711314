export const APP_SOURCE = "prepfq";
export const APP_URL = "https://prepfq.planwellguide.com";

export const AUTH: string = "/auth";
export const DASHBOARD: string = "/";
export const LOGIN: string = `${AUTH}/log-in`;
export const COMPLETION: string = `${AUTH}/completion`;
export const CONFIRMATION: string = `${AUTH}/confirmation`;
export const RECOVER_EMAIL: string = `${AUTH}/recover-email`;
export const FORGOT_PASSWORD: string = `${AUTH}/forgot-password`;
export const REGISTRATION: string = `${AUTH}/registration`;
export const RESET_PASSWORD: string = `${AUTH}/reset-password`;
export const HANDLER: string = `${AUTH}/handler`;
export const ROUTE_404: string = `/404`;
export const CONTACT_US: string = `/contact-us`;
export const TERMS_OF_SERVICE: string = `/terms-of-service`;
export const PRIVACY_POLICY: string = `/privacy-policy`;

export const PROFILE: string = `/profile`;
export const PROFILE_GENERAL: string = `${PROFILE}/general`;
export const PROFILE_ACCOUNT: string = `${PROFILE}/account`;
export const PROFILE_BILLING: string = `${PROFILE}/billing`;
export const PROFILE_NOTIFICATIONS: string = `${PROFILE}/notifications`;
export const DEMOGRAPHICS: string = `/demographics`;
export const PREP_FQ: string = `/prep-fq`;
export const PREP_FQ_REPORT: string = `${PREP_FQ}/report`;
export const PREP_FQ_PDF: string = `${PREP_FQ}/pdf`;
export const PREP_FQ_PDF_VIEWER: string = `${PREP_FQ}/pdf-viewer`;
export const PREP_FQ_INTRO: string = `${PREP_FQ}/introduction`;
export const PREMIUM: string = `/premium`;
export const PREMIUM_DASHBOARD: string = `${PREMIUM}/dashboard`;
export const PREMIUM_PRIORITIES: string = `${PREMIUM}/priorities`;
export const JOURNAL_ENTRIES: string = `${PREMIUM_DASHBOARD}/journal-entries`;
export const UPGRADE: string = `/personal-preparedness-package`;

export const ASIP: string = `/asip`;
export const ASIP_INTRO: string = `/intro`;
export const ASIP_CONCLUSION: string = `/conclusion`;
export const ASIP_OVERVIEW: string = `/overview`;
export const ASIP_PROFILES: string = `${ASIP}/profiles`;
export const ASIP_REMINDER: string = `asip-reminder`;
export const DEAR_DR_VIEWER: string = `/dear-doctor`;
export const DEAR_SDM_VIEWER: string = `/dear-sdm`;
export const DEAR_DR_PDF: string = `/dear-doctor-pdf`;
export const DEAR_SDM_PDF: string = `/dear-sdm-pdf`;
