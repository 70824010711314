import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const layoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      // backgroundColor: "#F6F7F9",
    },
  })
);

export const headerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 125,
      width: "100%",
      maxWidth: "100vw",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
      zIndex: 100,
      "@media (max-width: 1200px)": {
        height: 77,
      },
    },
    whiteText: {
      color: "white",
      lineHeight: "1.2",
    },
    headerLeft: {
      height: "100%",
      minWidth: "550px",
      backgroundColor: "#3E4066",
      borderRadius: "0 0 20px 0",
      padding: "12px 24px",
      "@media (max-width: 1200px)": {
        minWidth: 200,
        borderRadius: 0,
        padding: "12px 24px",
      },
    },
    headerLeftContents: {
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "center",
      "@media (max-width: 1200px)": {
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
    logoContainer: {
      marginRight: "24px",
      cursor: "pointer",
      "@media (max-width: 1200px)": {
        display: "none",
      },
    },
    logoContainerMobile: {
      marginRight: "24px",
      "@media (min-width: 1200px)": {
        display: "none",
      },
    },
    titleContainer: {
      "@media (max-width: 1200px)": {
        display: "none",
      },
    },
    title: {
      fontWeight: "bolder",
      fontSize: 34,
      marginBottom: 4,
      fontStyle: "italic",
    },
    subtitle: {
      fontWeight: "lighter",
      fontSize: 34,
      marginBottom: 12,
      fontStyle: "italic",
    },
    welcome: {
      fontSize: 20,
      marginTop: 18,
    },
    headerRight: {
      height: 77,
      width: "100%",
      backgroundColor: "#3E4066",
      padding: "12px 24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    navRow: {
      height: "100%",
      "@media (max-width: 1200px)": {
        display: "none",
      },
    },
    linksList: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      listStyle: "none",
      padding: 0,
      margin: "0 36px 0 0",
      height: "100%",
      "@media (min-width: 1500px)": {
        margin: "0 84px 0 0",
      },
    },
    linkButton: {
      color: "white !important",
      textTransform: "none !important" as any,
      fontSize: "18px !important",
      padding: "0 8px !important",
      "@media (min-width: 1500px)": {
        fontSize: "18px !important",
        padding: "0 16px !important",
      },
    },
    iconsRow: {},
    icon: {
      color: "white",
      fontSize: "36px !important",
      "@media (max-width: 1200px)": {
        fontSize: "24px !important",
      },
    },
    menuIcon: {
      paddingLeft: 0,
      "@media (min-width: 1200px)": {
        display: "none !important",
      },
    },
    mobileTitle: {
      fontSize: "18px",
      marginBottom: 0,
      "@media (min-width: 1200px)": {
        display: "none !important",
      },
    },
    dropDownMenu: {
      minWidth: "300px",
    },
    dropDownMenuItem: {
      minWidth: "150px",
      fontSize: "16px !important",
    },
    dropDownMenuIcon: {
      minWidth: "40px !important",
    },
    inline: {
      display: "inline",
    },
    notificationsList: {
      minWidth: "300px",
      maxWidth: "36ch",
      backgroundColor: theme.palette.background.paper,
    },
    notificationContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    notificationTitleContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    notificationTitle: {
      fontSize: "16px",
    },
    notificationDate: {
      fontSize: "13px",
    },
    notificationDescription: {
      width: "100%",
      marginBottom: 6,
      fontSize: "14px",
    },
    notificationLink: {
      width: "100%",
      textDecoration: "underline",
      fontSize: "14px",
      color: "#002655",
    },
    drawer: {
      width: 300,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 300,
    },
    drawerContainer: {
      overflow: "auto",
    },
    drawerContent: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    drawerToolbar: {
      minHeight: "80px !important",
      backgroundColor: "#3E4066",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    drawerListItem: {
      padding: "12px 24px 12px 24px !important",
    },
    drawerLink: {
      fontSize: "16px !important",
      fontWeight: "bold !important" as any,
      textDecoration: "none !important",
    },
    authHeader: {
      position: "fixed",
      zIndex: 100,
      margin: "0 auto 0 auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      backgroundColor: "white",
      padding: "12px 12px",
    },
    authHeaderWordMark: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    authHeaderLogoContainer: {
      position: "relative",
    },
    authHeaderTitle: {
      marginLeft: "16px !important",
    },
    blue: {
      color: "#5db2e3",
    },
    prepFqHeader: {
      zIndex: 100,
      margin: "0 auto 0 auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundColor: "white",
      padding: "24px 24px",
      "@media (max-width: 960px)": {
        padding: "12px 12px",
      },
      marginBottom: "24px",
    },
    prepFqTitle: {
      fontSize: "24px !important",
      marginTop: "12px !important",
    },
  })
);

export const bodyStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "calc(100vh - 125px)",
      paddingBottom: 0,
      paddingTop: 0,
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 1200px)": {
        minHeight: "calc(100vh - 80px)",
      },
    },
    asipQuestionnaireRoot: {
      height: "100vh",
      paddingBottom: 0,
      paddingTop: 0,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
  })
);

export const footerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#182A53",
      borderRadius: "0 150px 0 0",
      marginRight: 8,
      padding: "96px 48px 48px 48px",
      zIndex: 10,
    },
    content: {
      maxWidth: "1200px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: "0 auto",
    },
    divider: {
      height: "2px",
      width: "100%",
      backgroundColor: "#5db2e3",
      marginTop: "64px",
      marginBottom: "48px",
    },
    titleContainer: {
      height: "80px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      marginBottom: "40px",
    },
    titleContainerAlt1: {
      marginBottom: "24px",
    },
    titleContainerAlt2: {
      marginBottom: "16px",
    },
    paragraphContainer: {
      paddingRight: "48px",
    },
    paragraph: {
      color: "white",
      fontSize: "18px",
      lineHeight: "1.5",
    },
    title: {
      color: "#5db2e3",
      textTransform: "uppercase",
      fontSize: "22px",
      position: "relative",
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: -4,
        left: 0,
        height: "0",
        width: "40px",
        borderBottom: "2px solid white",
      },
    },
    white: {
      color: "white",
    },
    linksList: {
      margin: 0,
      padding: 0,
    },
    link: {
      color: "white !important",
      fontSize: "16px !important",
      paddingLeft: "0px !important",
      minWidth: "0 !important",
    },
    socialsList: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "row",
      padding: 0,
      margin: 0,
      listStyle: "none",
    },
    socialsListItem: {
      listStyle: "none",
    },
    socialIcon: {
      color: "white !important",
      fontSize: "36px !important",
    },
    socialIconFirst: {
      paddingLeft: "0px !important",
    },
    copyrightContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    copyRight: {
      color: "white",
      textTransform: "capitalize",
      fontSize: "16px",
    },
  })
);
