import {
  browserSessionPersistence,
  confirmPasswordReset,
  getAuth,
  browserLocalPersistence,
  sendEmailVerification,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { httpsCallable, getFunctions } from "firebase/functions";
import { API_AUTH } from "../../../constants/api_routes";
import { firebaseApp } from "../../../firebase/firebaseClient";
import { IncomingUserData } from "../../../models/users";

export const onCreateUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  const auth = getAuth(firebaseApp);
  return createUserWithEmailAndPassword(auth, email, password);
};

export const sendConfirmationEmail = async () => {
  const auth = getAuth(firebaseApp);
  return sendEmailVerification(auth.currentUser);
};

export const createUser = async (userData: IncomingUserData) => {
  const functions = getFunctions(firebaseApp);
  const onCreateUser = httpsCallable(functions, "onCreateUser");
  return onCreateUser(userData);
};

export const onConfirmPasswordReset = async (
  code: string,
  newPassword: string
) => {
  const auth = getAuth();
  return confirmPasswordReset(auth, code, newPassword);
};

export const sendPasswordResetEmail = async (email: string) => {
  const functions = getFunctions(firebaseApp);
  const onSendPasswordResetEmail = httpsCallable(
    functions,
    "onSendPasswordResetEmail"
  );
  return onSendPasswordResetEmail({ email });
};

export const logInWithEmailAndPassword = async (
  keepMeSignedIn: boolean,
  email: string,
  password: string
) => {
  const auth = getAuth(firebaseApp);
  await setPersistence(
    auth,
    keepMeSignedIn ? browserLocalPersistence : browserSessionPersistence
  );
  return signInWithEmailAndPassword(auth, email, password);
};

export const resendConfirmationEmail = async (email: string) => {
  const functions = getFunctions(firebaseApp);
  const onSendConfirmationEmail = httpsCallable(
    functions,
    "onSendConfirmationEmail"
  );
  return onSendConfirmationEmail({ email });
};

export const postUserToken = async (token) => {
  const url = process.env.NEXT_PUBLIC_BASE_API_URL + API_AUTH;
  const data = { token };
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const deleteUserToken = async () => {
  const url = process.env.NEXT_PUBLIC_BASE_API_URL + API_AUTH;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

export const onSignOut = async () => {
  const auth = getAuth(firebaseApp);
  await signOut(auth);
  await deleteUserToken();
};

// =============================================================================
// ERROR HANDLING
// =============================================================================
export const getAuthError = (error: string, errObj?: any) => {
  switch (error) {
    case "auth/expired-action-code": {
      return "tokenExpired";
    }
    case "auth/invalid-action-code": {
      return "tokenInvalid";
    }
    case "auth/user-disabled": {
      return "userDisabled";
    }
    case "auth/user-not-found": {
      return "userNotFound";
    }
    case "auth/weak-password": {
      return "weakPassword";
    }
    case "auth/invalid-email": {
      return "invalidEmail";
    }
    case "auth/account-exists-with-different-credential":
    case "auth/email-already-in-use": {
      return "userExists";
    }
    case "auth/too-many-requests": {
      return "tooManyRequests";
    }
    case "auth/wrong-password": {
      return "wrongPassword";
    }
    case "auth/cancelled-popup-request":
    case "auth/popup-blocked":
    case "auth/popup-closed-by-user": {
      return "";
    }
    case "auth/auth-domain-config-required":
    case "auth/operation-not-allowed":
    case "auth/unauthorized-domain": {
      return "generic";
    }
    default: {
      return "generic";
    }
  }
};
