import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  limit,
} from "@firebase/firestore";
import useSWR from "swr";
import { firebaseApp } from "../../../firebase/firebaseClient";
import { PrepFqProfile } from "../../../models/users";

const fetcher = async (url: string) => {
  const splits = url.split(`/prep_fq_profiles/`);
  const userId = splits[1];
  const firestore = getFirestore(firebaseApp);
  const profileQuery = query(
    collection(firestore, "prep_fq_profiles"),
    where("user_id", "==", userId),
    limit(1)
  );
  const profileSnap = await getDocs(profileQuery);
  if (profileSnap.docs.length > 0) {
    const profile = profileSnap.docs[0].data() as PrepFqProfile;
    return profile;
  } else {
    throw Error("profile not found");
  }
};

function usePrepFqProfile(userId?: string) {
  const { data, mutate, isValidating, error } = useSWR(
    userId ? `/prep_fq_profiles/${userId}` : null,
    fetcher
  );
  return {
    profile: data,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    mutate,
  };
}

export default usePrepFqProfile;
