import React, { useEffect } from "react";
import { useRouter } from "next/router";
import {
  CONFIRMATION,
  DEAR_DR_PDF,
  DEAR_SDM_PDF,
  HANDLER,
  PREP_FQ_PDF,
  RECOVER_EMAIL,
  RESET_PASSWORD,
} from "../constants/routes";
const pagesToExclude = [
  PREP_FQ_PDF,
  DEAR_SDM_PDF,
  DEAR_DR_PDF,
  CONFIRMATION,
  RESET_PASSWORD,
  HANDLER,
  RECOVER_EMAIL,
];
function PageSense() {
  const router = useRouter();
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production" &&
      process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === "true" &&
      pagesToExclude.every((page) => !router.pathname.includes(page))
    ) {
      var e = document.createElement("script");
      e.type = "text/javascript";
      e.async = true;
      e.src = `https://cdn.pagesense.io/js/planwellguide/${process.env.NEXT_PUBLIC_PAGESENSE_TRACKING_ID}.js`;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(e, x);
    }
  }, [router]);
  return <React.Fragment />;
}

export default PageSense;
