import { Button, Grid } from "@material-ui/core";
import React from "react";
import { footerStyles } from "./styles/prodStyles";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import TwitterIcon from "./icons/TwitterIcon";
import Link from "next/link";
import {
  CONTACT_US,
  PRIVACY_POLICY,
  TERMS_OF_SERVICE,
} from "../constants/routes";

const ProdFooter = () => {
  const { t } = useTranslation(["footer", "common"]);
  const classes = footerStyles();
  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <Grid spacing={1} container>
          <Grid item lg={2} sm={2} xs={3}>
            <div
              className={classes.titleContainer}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Image
                src="/logo_dark.svg"
                alt={t("common:title")}
                layout="intrinsic"
                height="80"
                width="107.5"
              />
            </div>
          </Grid>
          <Grid item lg={7} sm={7} xs={9}>
            <div className={classes.paragraphContainer}>
              <p className={classes.paragraph}>{t("footer:paragraph")}</p>
            </div>
          </Grid>
          <Grid item lg={3} sm={3} xs={12}>
            <div className={classes.socialMediaContents}>
              <div
                className={clsx(
                  classes.titleContainer,
                  classes.titleContainerAlt2
                )}
                style={{ height: "unset" }}
              >
                <p className={classes.title}>
                  {t("footer:titles.follow")}{" "}
                  <span className={classes.white}>{t("footer:titles.us")}</span>
                </p>
              </div>
              <ul className={classes.socialsList}>
                <IconButton
                  className={classes.socialIconFirst}
                  aria-label="facebook"
                  href="https://www.facebook.com/Plan-Well-Guide-1993806247413539/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FacebookIcon className={classes.socialIcon} />
                </IconButton>
                <IconButton
                  aria-label="instagram"
                  href="https://www.instagram.com/plan_well_guide/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <InstagramIcon className={classes.socialIcon} />
                </IconButton>
                <IconButton
                  aria-label="twitter"
                  href="https://twitter.com/plan_well_guide"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <TwitterIcon className={classes.socialIcon} />
                </IconButton>
              </ul>
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.linksContents} container>
          <Grid
            className={classes.linkContainer}
            item
            lg={"auto"}
            md={"auto"}
            sm={"auto"}
            xs={12}
          >
            <Button variant="text" className={classes.link}>
              <Link href={CONTACT_US}>{t("footer:links.contact")}</Link>
            </Button>
          </Grid>
          <Grid
            className={classes.linkContainer}
            item
            lg={"auto"}
            md={"auto"}
            sm={"auto"}
            xs={12}
          >
            <Link passHref href={PRIVACY_POLICY}>
              <Button variant="text" className={classes.link}>
                {t("footer:links.pp")}
              </Button>
            </Link>
          </Grid>
          <Grid
            className={classes.linkContainer}
            item
            lg={"auto"}
            md={"auto"}
            sm={"auto"}
            xs={12}
          >
            <Link passHref href={TERMS_OF_SERVICE}>
              <Button variant="text" className={classes.link}>
                {t("footer:links.tos")}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <div className={classes.divider} style={{ marginTop: 24 }} />
        <div className={classes.copyrightContainer}>
          <p className={classes.copyRight}>{t("footer:copyright")}</p>
        </div>
      </div>
    </footer>
  );
};

export default ProdFooter;
