import React from "react";
import { SvgIcon } from "@material-ui/core";

const HouseIcon = (props) => {
  return (
    <SvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 526.7 526.7"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M1010.6-107.4c0.5-0.5,1-0.9,1.5-1.3C1011.6-108.2,1011.1-107.8,1010.6-107.4z" />
      <path d="M1012.1-108.6c0.8-0.6,1.6-1.1,2.4-1.6C1013.7-109.7,1012.9-109.2,1012.1-108.6z" />
      <g>
        <path
          d="M379.2,471.2h-42.3c-18.7,0-33.9-15.2-33.9-33.9v-89.7c0-8.4-6.9-15.3-15.3-15.3h-48.9
		c-8.4,0-15.3,6.9-15.3,15.3v89.9c0,18.6-15.1,33.7-33.7,33.7h-42.5c-21.7,0-39.3-17.6-39.3-39.3V281.3c0-3.4,0.4-6.7,1.3-10
		c1.2-6.8,4.4-12.9,9.3-17.8l121.2-121.2c6.4-6.4,14.9-9.9,23.9-9.9c9,0,17.5,3.5,23.9,9.9l121.2,121.2c7.2,7.2,10.8,17.5,9.7,27.6
		c0,0,0,0.1,0,0.1v150.6C418.5,453.6,400.9,471.2,379.2,471.2z M238.8,306.6h48.9c22.6,0,41,18.4,41,41v89.7c0,4.6,3.7,8.3,8.3,8.3
		h42.3c7.5,0,13.6-6.1,13.6-13.6V281.3c0-0.2,0-0.4,0-0.5l0-1.1l0.2-1.1c0.4-2.6-0.5-5.1-2.3-6.9L269.4,150.5
		c-1.5-1.5-3.6-2.4-5.7-2.4c-2.2,0-4.2,0.8-5.7,2.4L136.7,271.8c-1.5,1.5-2.1,3.2-2.3,4.4l-0.1,0.7l-0.2,0.7
		c-0.3,1.2-0.5,2.5-0.5,3.7v150.6c0,7.5,6.1,13.6,13.6,13.6h42.5c4.4,0,8-3.6,8-8v-89.9C197.8,325,216.2,306.6,238.8,306.6z"
        />
        <path
          d="M460.1,252.3c-2.5,2.7-5.9,4.1-9.4,4.1c-3.1,0-6.3-1.1-8.8-3.5L290.8,111.6c-15.5-14.5-39.5-14.5-55,0
		L84.7,252.9c-2.5,2.3-5.6,3.5-8.8,3.5c-3.4,0-6.9-1.4-9.4-4.1c-4.8-5.2-4.6-13.3,0.6-18.1l187.2-175c0.1-0.1,0.1-0.1,0.2-0.2
		c0.3-0.3,0.6-0.6,1-0.8c0.5-0.4,1-0.7,1.5-1c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.5-0.3c0.1-0.1,0.3-0.1,0.4-0.2
		c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0
		c0.2-0.1,0.4-0.1,0.6-0.1c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.3,0,0.5-0.1c0.1,0,0.3,0,0.4,0c0,0,0,0,0,0
		c0.6,0,1.2,0,1.8,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.5,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1
		c0.2,0,0.4,0.1,0.6,0.1c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.3,0.1,0.5,0.2c0.1,0,0.2,0.1,0.3,0.1
		c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.1,0.1,0.2,0.1,0.4,0.2c0.2,0.1,0.4,0.2,0.6,0.4c0.3,0.2,0.7,0.4,1,0.7
		c0.2,0.2,0.4,0.3,0.6,0.5c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0,0.1,0.1,0.1,0.1l0.1,0.1l187.3,175C464.7,239,465,247.1,460.1,252.3z"
        />
        <path d="M391.7,153.9l-43.1-42.3V74.3c0-4.4,4.6-7.9,10.2-7.9h22.6c5.7,0,10.3,3.6,10.3,8V153.9z" />
      </g>
    </SvgIcon>
  );
};

export default HouseIcon;
