import { getFirestore, collection, addDoc } from "firebase/firestore";
import { firebaseApp } from "../firebase/firebaseClient";
import { PwgError } from "../models/common";

const publishErrorClient = async (pwgError: PwgError) => {
  if (pwgError.error) {
    const firestore = getFirestore(firebaseApp);
    const errorWithDate = {
      ...pwgError,
      date_string: pwgError.date_created
        ? new Date(pwgError.date_created).toString()
        : new Date().toString(),
    } as PwgError;
    await addDoc(collection(firestore, `app_errors`), errorWithDate as any);
  }
};

const logging = {
  d: (message?: any, options?: any) => {
    if (process.env.NODE_ENV === "development") {
      console.log(message, options);
    }
  },
  i: (message?: any, options?: any) => {
    if (process.env.NODE_ENV === "development") {
      console.info(message, options);
    }
  },
  e: async (message?: any, options?: any, pwgError?: PwgError) => {
    if (process.env.NODE_ENV === "development") {
      console.error(message, options);
      return;
    } else {
      if (pwgError) {
        await publishErrorClient(pwgError);
        return;
      } else {
        return;
      }
    }
  },
};

export default logging;
