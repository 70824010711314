import { combineReducers } from "redux";
import sessionReducer, {
  SESSION_INITIAL_STATE,
} from "../reducers/session/reducers";
import { HYDRATE } from "next-redux-wrapper";
import usersReducer, { USERS_INITIAL_STATE } from "../reducers/users/reducers";
import prepFqReducer, {
  PREP_FQ_INITIAL_STATE,
} from "../reducers/prep_fq/reducers";

const USER_LOGOUT = "user logout";
export const userLogout = () => ({ type: USER_LOGOUT });

const appReducer = combineReducers({
  sessionState: sessionReducer,
  usersState: usersReducer,
  prepFqState: prepFqReducer,
});

export const APP_INITIAL_STATE = {
  sessionState: SESSION_INITIAL_STATE,
  usersState: USERS_INITIAL_STATE,
  prepFqState: PREP_FQ_INITIAL_STATE,
};

const rootReducer = (state: any, action: any) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    return nextState;
  } else {
    if (action.type === USER_LOGOUT) {
      state = Object.assign({}, APP_INITIAL_STATE);
    }

    return appReducer(state, action);
  }
};

export default rootReducer;
