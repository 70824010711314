import { useRouter } from "next/router";
import React from "react";
import { PREMIUM_DASHBOARD, PREP_FQ } from "../constants/routes";
import AltFooter from "./AltFooter";
import DevFooter from "./DevFooter";
import ProdFooter from "./ProdFooter";

const Footer = () => {
  const router = useRouter();
  const isPrepFq =
    router.pathname.includes(PREMIUM_DASHBOARD) ||
    router.pathname.includes(PREP_FQ);
  if (isPrepFq) {
    return <AltFooter />;
  } else {
    return <ProdFooter />;
  }
};

export default Footer;
