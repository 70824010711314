import React from "react";
import { SvgIcon } from "@material-ui/core";

const TwitterIcon = (props) => {
  return (
    <SvgIcon data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" {...props}>
      <path className="twitter-1" d="M101.2909,2.9391c-54.5063,0-98.7581,44.0714-98.7581,98.3691,0,54.3284,44.2518,98.414,98.7581,98.414,54.5669,0,98.8337-44.0856,98.8337-98.414,0-54.3591-44.2668-98.3691-98.8337-98.3691Z" />
      <path className="twitter-2" d="M150.4928,79.2654l.0614,3.114c0,32.0115-24.4781,68.922-69.2212,68.922a68.4763,68.4763,0,0,1-37.2616-10.9293,42.2985,42.2985,0,0,0,5.7784.3811,49.0407,49.0407,0,0,0,30.2266-10.3955,24.4017,24.4017,0,0,1-22.731-16.8219,22.8479,22.8479,0,0,0,4.5824.4425,24.316,24.316,0,0,0,6.4075-.8542A24.2578,24.2578,0,0,1,48.838,89.401v-.3055a23.9841,23.9841,0,0,0,10.975,3.0077A24.1188,24.1188,0,0,1,49.0372,71.9839a23.74,23.74,0,0,1,3.3109-12.182,69,69,0,0,0,50.1073,25.28,23.1159,23.1159,0,0,1-.5984-5.5265,24.25,24.25,0,0,1,24.28-24.1954,24.6559,24.6559,0,0,1,17.7958,7.6176,48.4562,48.4562,0,0,0,15.42-5.8162,23.9748,23.9748,0,0,1-10.7135,13.3725,48.9276,48.9276,0,0,0,13.9787-3.8313,50.3511,50.3511,0,0,1-12.1245,12.5631Z" />
    </SvgIcon >
  )
}

export default TwitterIcon;