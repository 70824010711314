import { PwgAlert } from "../../models/common";
import { UserCookies } from "../../models/users";
import {
  SET_PAGE,
  SET_AUTH_USER,
  SET_ALERT,
} from "./constants";

export const rSetPage = (page: string) => ({ type: SET_PAGE, page });
export const rSetAuthUser = (authUser: UserCookies | null) => ({
  type: SET_AUTH_USER,
  authUser,
});
export const rSetAlert = (alert: PwgAlert) => ({
  type: SET_ALERT,
  alert: {
    ...alert,
  },
});
