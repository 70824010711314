import React, { useCallback, useMemo } from "react";
import { headerStyles } from "./styles/prodStyles";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Link from "next/link";
import {
  CONTACT_US,
  DASHBOARD,
  PREMIUM_DASHBOARD,
  PREP_FQ,
  PREP_FQ_INTRO,
  PROFILE_GENERAL,
} from "../constants/routes";
import { Tooltip } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useRouter } from "next/router";
import { onSignOut } from "../views/auth/fetchers/auth";
import MenuIcon from "@material-ui/icons/Menu";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useAuth } from "../firebase/useAuth";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import usePrepFqProfile from "../views/prepFq/fetchers/usePrepFqProfile";
import Skeleton from "@material-ui/lab/Skeleton";
import { routerPushAndScroll } from "../utils/router";
import HouseIcon from "./icons/HouseIcon";
import PrepFqIcon from "./icons/PrepFqIcon";

const ProdHeader = () => {
  const { t } = useTranslation(["header", "common"]);
  const classes = headerStyles();
  const router = useRouter();
  const authUser = useAuth();
  const { profile: prepFqProfile, isLoading: prepFqProfileLoading } =
    usePrepFqProfile(authUser?.user?.uid);
  const loading = prepFqProfileLoading;

  const [anchorUserEl, setAnchorUserEl] = React.useState(null);

  const closeUserMenu = () => {
    setAnchorUserEl(null);
  };
  const openUserMenu = (e) => {
    setAnchorUserEl(e.currentTarget);
  };
  const toProfile = () => {
    closeUserMenu();
    routerPushAndScroll(router, PROFILE_GENERAL);
  };
  const toContact = () => {
    closeUserMenu();
    routerPushAndScroll(router, CONTACT_US);
  };
  const goHome = useCallback(() => {
    router.push(DASHBOARD);
  }, [router]);
  const logout = async () => {
    closeUserMenu();
    await onSignOut();
  };
  const startPrepFq = useCallback(
    (e) => {
      if (prepFqProfile?.prep_fq_status === "not_started") {
        routerPushAndScroll(router, `${PREP_FQ_INTRO}`);
      } else if (prepFqProfile?.prep_fq_status === "in_progress") {
        routerPushAndScroll(router, `${PREP_FQ}/${prepFqProfile.current_page}`);
        //todo add premium check  && is_premium
      } else if (prepFqProfile?.prep_fq_status === "completed") {
        routerPushAndScroll(router, `${PREMIUM_DASHBOARD}`);
      }
    },
    [router, prepFqProfile?.prep_fq_status, prepFqProfile?.current_page]
  );
  const headerLinks = useMemo(() => {
    return [
      {
        label: "home",
        icon: (
          <HouseIcon
            className={clsx(classes.icon, {
              [classes.iconSelected]: router.pathname === DASHBOARD,
            })}
            style={{ fontSize: "64px" }}
          />
        ),
        onClick: goHome,
      },
      {
        label: "prepFq",
        icon: (
          <PrepFqIcon
            className={clsx(classes.icon, {
              [classes.iconSelected]:
                router.pathname.includes(PREMIUM_DASHBOARD),
            })}
            style={{ fontSize: "64px" }}
          />
        ),
        onClick: startPrepFq,
      },
    ];
  }, [
    router.pathname,
    classes.icon,
    classes.iconSelected,
    goHome,
    startPrepFq,
  ]);
  return (
    <header className={classes.root}>
      <div className={classes.headerLeft}>
        <div className={classes.headerLeftContents}>
          <h2
            className={clsx(
              classes.whiteText,
              classes.subtitle,
              classes.mobileTitle
            )}
          >
            {t(`header:pageTitles.${router.pathname}`)}
          </h2>
          <div className={classes.logoContainer}>
            <Link passHref href={DASHBOARD}>
              <div>
                <Image
                  src="/logo_dark.svg"
                  alt={t("common:title")}
                  layout="fixed"
                  height="60"
                  width="80.6"
                />
              </div>
            </Link>
          </div>
          <div className={classes.titleContainer}>
            <h2 className={clsx(classes.whiteText, classes.title)}>
              {t(`header:pageTitles.${router.pathname}`)}
            </h2>
          </div>
        </div>
      </div>
      <div className={classes.headerRight}>
        <nav className={classes.iconsRow}>
          <ul className={classes.linksList}>
            <div>
              <p className={classes.navigateTo}>
                {t(`header:headerLinks.navigateTo`)}
              </p>
            </div>
            {headerLinks.map((link) => {
              if (loading) {
                return (
                  <Skeleton
                    key={`${link.label}-loading`}
                    variant="circle"
                    className={classes.iconButtonLoading}
                  />
                );
              } else {
                return (
                  <Tooltip
                    title={t(`header:headerLinks.${link.label}`)}
                    className={classes.tooltip}
                    key={link.label}
                  >
                    <IconButton
                      className={classes.iconButton}
                      aria-label="profile"
                      onClick={link.onClick}
                    >
                      {link.icon}
                    </IconButton>
                  </Tooltip>
                );
              }
            })}
            <Tooltip
              title={t(`header:headerLinks.menu`)}
              className={classes.tooltip}
              key={"menu"}
            >
              <IconButton
                className={classes.iconButton}
                aria-label="profile"
                onClick={openUserMenu}
              >
                <MenuIcon
                  className={classes.inverseIcon}
                  style={{
                    fontSize: "48px",
                    borderRadius: "50%",
                    padding: "15%",
                  }}
                />
              </IconButton>
            </Tooltip>
          </ul>
          <Menu
            id="profile-menu"
            anchorEl={anchorUserEl}
            keepMounted={false}
            open={Boolean(anchorUserEl)}
            onClose={closeUserMenu}
          >
            <MenuItem onClick={toProfile} className={classes.dropDownMenuItem}>
              <ListItemIcon className={classes.dropDownMenuIcon}>
                <AccountBoxIcon />
              </ListItemIcon>
              {t(`header:menu.profile`)}
            </MenuItem>
            <MenuItem onClick={toContact} className={classes.dropDownMenuItem}>
              <ListItemIcon className={classes.dropDownMenuIcon}>
                <ContactSupportIcon />
              </ListItemIcon>
              {t(`header:menu.contact`)}
            </MenuItem>
            <MenuItem onClick={logout} className={classes.dropDownMenuItem}>
              <ListItemIcon className={classes.dropDownMenuIcon}>
                <ExitToAppIcon />
              </ListItemIcon>
              {t(`header:menu.logout`)}
            </MenuItem>
          </Menu>
        </nav>
      </div>
    </header>
  );
};

export default ProdHeader;
