import useSWR from "swr";
import { firebaseApp } from "./firebaseClient";
import { User } from "../models/users";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const fetcher = async (url: string) => {
  if (url) {
    const splits = url.split(`/users/`);
    const user_id = splits[1];
    const firestore = getFirestore(firebaseApp);
    const userSnap = await getDoc(doc(firestore, `users`, user_id));
    return userSnap.data();
  } else {
    return undefined;
  }
};

function useUser(user_id?: string, revalidateOnFocus?: boolean) {
  const { data, mutate, isValidating, error } = useSWR(
    user_id ? `/users/${user_id}` : null,
    fetcher,
    { revalidateOnFocus }
  );
  const result: {
    user: User;
    isLoading: boolean;
    isValidating: boolean;
    isError: boolean | string;
    mutate: any;
  } = {
    user: data as any,
    isLoading: !error && !data,
    isValidating,
    isError: error,
    mutate,
  };
  return result;
}

export default useUser;
