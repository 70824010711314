import React from 'react';
import { layoutStyles } from './styles';

const AuthLayout = ({ children }) => {
  const classes = layoutStyles()
  return (
    <main className={classes.root}>
      {children}
    </main>
  )
}

export default AuthLayout;