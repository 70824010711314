import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../src/theme/theme";
import { wrapper } from "../src/store/store";
import AuthProvider from "../src/firebase/AuthProvider";
import { appWithTranslation } from "next-i18next";
import App from "next/app";
import AuthLayout from "../src/layout/AuthLayout";
import DashLayout from "../src/layout/DashLayout";
import { AUTH, PREMIUM_DASHBOARD, PREP_FQ } from "../src/constants/routes";
import "../src/theme/app.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "nprogress/nprogress.css";
import dynamic from "next/dynamic";
import PrepFqLayout from "../src/layout/PrepFqLayout";
import smoothscroll from "smoothscroll-polyfill";
import { firebaseApp } from "../src/firebase/firebaseClient";
import ZohoChat from "../src/firebase/ZohoChat";
import GoogleAnalytics from "../src/firebase/GoogleAnalytics";
import { install } from "resize-observer";
import PageSense from "../src/firebase/PageSense";

if (typeof window !== "undefined" && !window.ResizeObserver) {
  install();
}

const TopProgressBar = dynamic(
  () => {
    return import("../src/components/TopProgressBar");
  },
  { ssr: false }
);
function PwgApp(props) {
  const { Component, pageProps, router } = props;
  //MUI
  React.useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  //smooth scrolling and firebase performance
  React.useEffect(() => {
    if (typeof window !== undefined) {
      smoothscroll.polyfill();
      import("firebase/performance").then((result) => {
        const perf = result.getPerformance(firebaseApp);
      });
    }
  }, []);
  const getLayout = (pathname: string) => {
    if (pathname.includes(AUTH)) {
      return (
        <AuthLayout>
          <Component {...pageProps} />
        </AuthLayout>
      );
    } else if (
      pathname.includes(PREP_FQ) ||
      pathname.includes(PREMIUM_DASHBOARD)
    ) {
      return (
        <PrepFqLayout>
          <Component {...pageProps} />
        </PrepFqLayout>
      );
    } else {
      return (
        <DashLayout>
          <Component {...pageProps} />
        </DashLayout>
      );
    }
  };
  return (
    <React.Fragment>
      <ZohoChat />
      <GoogleAnalytics />
      <PageSense />
      <ThemeProvider theme={theme}>
        <TopProgressBar />
        <CssBaseline />
        <AuthProvider>{getLayout(router.pathname)}</AuthProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

PwgApp.getInitialProps = async (appContext) => ({
  ...(await App.getInitialProps(appContext)),
});

const wrappedWithI18n = appWithTranslation(PwgApp);
export default wrapper.withRedux(wrappedWithI18n);
