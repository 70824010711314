import React from "react";
import { SvgIcon } from "@material-ui/core";

const FacebookIcon = (props) => {
  return (
    <SvgIcon data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200"  {...props}>
      <path className="facebook-1" d="M101.0559,1.646c-54.304,0-98.5,43.9986-98.5,98.0808,0,54.0977,44.1963,98.0971,98.5,98.0971,54.32,0,98.5-43.9994,98.5-98.0971,0-54.0822-44.18-98.0808-98.5-98.0808Z" />
      <path className="facebook-2" d="M125.5633,103.1686H109.5427v56.9191H85.7781V103.1686H74.5018v-20.09H85.7781V70.0827c0-9.2988,4.4407-23.8635,23.9771-23.8635l17.5972.06V65.7805H114.5751c-2.0911,0-5.0324,1.0565-5.0324,5.4793V83.1083h18.0814Z" />
    </SvgIcon >
  )
}

export default FacebookIcon;