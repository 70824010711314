import React, { useEffect } from "react";
import {
  CONFIRMATION,
  DEAR_DR_PDF,
  DEAR_SDM_PDF,
  HANDLER,
  PREP_FQ_PDF,
  RECOVER_EMAIL,
  RESET_PASSWORD,
} from "../constants/routes";
import { useRouter } from "next/router";

const pagesToExclude = [
  PREP_FQ_PDF,
  DEAR_SDM_PDF,
  DEAR_DR_PDF,
  CONFIRMATION,
  RESET_PASSWORD,
  HANDLER,
  RECOVER_EMAIL,
];
function ZohoChat() {
  const router = useRouter();
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "production" &&
      process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === "true" &&
      pagesToExclude.every((page) => !router.pathname.includes(page))
    ) {
      setTimeout(() => {
        (window as any).$zoho = (window as any).$zoho || {};
        (window as any).$zoho.salesiq = (window as any).$zoho.salesiq || {
          widgetcode: process.env.NEXT_PUBLIC_APP_ZOHO_SALESIQ_CODE,
          values: {},
          ready: function () {},
        };
        const d = document;
        let s;
        s = d.createElement("script");
        s.type = "text/javascript";
        s.id = "zsiqscript";
        s.defer = true;
        s.src = "https://salesiq.zoho.com/widget";
        let t;
        t = d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(s, t);
      }, 3000);
    }
  }, [router]);
  return <div id="zsiqwidget" />;
}

export default ZohoChat;
