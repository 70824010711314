import {
  CLEAR_PREP_FQ_REDUCER,
  SAVE_PREP_FQ_FORM,
  SET_PREP_FQ_PROFILE,
  SET_PREP_FQ_REPORT,
} from "./constants";
import { combineReducers } from "redux";
import {
  PrepFqAnswer,
  PrepFqProfile,
  PrepFqReportProps,
} from "../../models/users";
import {
  createReducerWithTaskName,
  createSimpleReducer,
  INITIAL_STATE_EMPTY_TASK,
  TaskState,
} from "../../utils/reducers";
import { PrepFqQuestion, PrepFqQuiz } from "../../models/prepFqQuiz";

const defaultProfile: PrepFqProfile = {
  prep_fq_profile_id: "",
  user_id: "",
  current_page: 0,
  current_question: 0,
  date_created: new Date().getTime(),
  date_pdf_last_generated: new Date().getTime(),
  date_last_updated: new Date().getTime(),
  prep_fq_status: "not_started",
  domains_completed: [],
  scored_results: [],
  suggestions: {
    doing_well: [],
    little_improvement: [],
    lots_of_improvements: [],
  },
  results: {
    max: 0,
    min: 0,
    scaled_score: 0,
    total_score: 0,
    totals: [],
  },
};
const defaultPrepFqQuiz: PrepFqQuiz = {
  date_last_updated: new Date().getTime(),
  domains: [],
  number_pages: 0,
  number_questions: 0,
  quiz_id: "quiz_id",
};
const answers: PrepFqAnswer[] = [];
const questions: PrepFqQuestion[] = [];
const prepFqReport: PrepFqReportProps = {
  average_score: {
    average_score: 0,
    best_score: 0,
    worst_score: 0,
  },
  score: {
    max: 0,
    min: 0,
    scaled_score: 0,
    total_score: 0,
    totals: [],
  },
  scored_results: [],
  suggestions: {
    doing_well: [],
    little_improvement: [],
    lots_of_improvements: [],
  },
};

export const PREP_FQ_INITIAL_STATE: {
  profile: PrepFqProfile;
  loadProfile: TaskState;
  saveForm: TaskState;
  prepFqReport: PrepFqReportProps;
} = {
  profile: {
    ...defaultProfile,
  },
  loadProfile: { ...INITIAL_STATE_EMPTY_TASK, loading: true },
  saveForm: { ...INITIAL_STATE_EMPTY_TASK },
  prepFqReport: { ...prepFqReport },
};

const prepFqRootReducer = combineReducers({
  profile: createSimpleReducer(
    SET_PREP_FQ_PROFILE,
    PREP_FQ_INITIAL_STATE.profile
  ),
  prepFqReport: createSimpleReducer(
    SET_PREP_FQ_REPORT,
    PREP_FQ_INITIAL_STATE.prepFqReport
  ),
  loadProfile: createReducerWithTaskName(
    SET_PREP_FQ_PROFILE,
    PREP_FQ_INITIAL_STATE.loadProfile
  ),
  saveForm: createReducerWithTaskName(
    SAVE_PREP_FQ_FORM,
    PREP_FQ_INITIAL_STATE.saveForm
  ),
});

const prepFqReducer = (state: any, action: any) => {
  if (action.type === CLEAR_PREP_FQ_REDUCER) {
    state = {
      ...PREP_FQ_INITIAL_STATE,
    };
  }
  return prepFqRootReducer(state, action);
};

export default prepFqReducer;
