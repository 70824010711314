import React from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import {
  CONFIRMATION,
  DEAR_DR_PDF,
  DEAR_SDM_PDF,
  HANDLER,
  PREP_FQ_PDF,
  RECOVER_EMAIL,
  RESET_PASSWORD,
} from "../constants/routes";

const pagesToExclude = [
  PREP_FQ_PDF,
  DEAR_SDM_PDF,
  DEAR_DR_PDF,
  CONFIRMATION,
  RESET_PASSWORD,
  HANDLER,
  RECOVER_EMAIL,
];
function GoogleAnalytics() {
  const router = useRouter();
  return (
    <Head>
      {process.env.NODE_ENV === "production" &&
        process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === "true" &&
        pagesToExclude.every((page) => !router.pathname.includes(page)) && (
          <script
            key="google-analytics-1"
            defer
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}`}
          />
        )}
      {process.env.NODE_ENV === "development" &&
        process.env.NEXT_PUBLIC_ENABLE_ANALYTICS === "true" &&
        pagesToExclude.every((page) => !router.pathname.includes(page)) && (
          <script
            key="google-analytics-2"
            defer
            async
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID}');`,
            }}
          />
        )}
    </Head>
  );
}

export default GoogleAnalytics;
