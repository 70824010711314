import { ThunkAction } from "redux-thunk";

export interface TaskState {
  loading: boolean | string | null;
  empty: boolean | string | null;
  error: boolean | string | null;
  success: string | boolean | null;
}
export const INITIAL_STATE_EMPTY_TASK: TaskState = {
  loading: false,
  error: false,
  success: false,
  empty: false,
};
export type ThunkResult<R> = ThunkAction<R, undefined, undefined, any>;

// insert item to the top of array immutably
export function insertItem(
  array: Array<any>,
  item: any,
  index: number
): Array<any> {
  let newArray = array.slice();
  newArray.splice(index, 0, item);
  return newArray;
}

// insert item to bottom of array immutably
export function insertItemBottom(array: Array<any>, item: any): Array<any> {
  return [...array, item];
}

// remove item immutably
export function removeItem(array: Array<any>, index: number): Array<any> {
  return array.filter((item, newIndex) => newIndex !== index);
}

// update item in array immutably
export function updateObjectInArray(
  array: Array<any>,
  item: any,
  index: number
): Array<any> {
  return array.map((oldItem, oldIndex) => {
    if (index !== oldIndex) {
      // This isn't the item we care about - keep it as-is
      return oldItem;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...oldItem,
      ...item,
    };
  });
}

// insert item (key) in array immutably
export const insertItemWithKey = (
  array: Array<any>,
  object: any
): Array<any> => {
  return [object, ...array];
};

// insert item (key) to bottom of array immutably
export const insertItemWithKeyBottom = (
  array: Array<any>,
  object: any
): Array<any> => {
  return [...array, object];
};

// remove item (key) from array immutably
export const removeItemWithKey = (
  array: Array<any>,
  object: any
): Array<any> => {
  return array.filter((item, index) => item.key !== object.key);
};

// update item (key) from array immutably
export const updateItemWithKey = (
  array: Array<any>,
  object: any
): Array<any> => {
  return array.map((item) => {
    if (item.key !== object.key) {
      // This isn't the item we care about - keep it as-is
      return item;
    }
    // Otherwise, this is the one we want - return an updated value
    return {
      ...item,
      ...object,
    };
  });
};

// insert array into array immutably
export const insertArray = (array: Array<any>, newArray: any): Array<any> => {
  return [...newArray, ...array];
};

// insert array into bottom of array immutably
export const insertArrayBottom = (
  array: Array<any>,
  newArray: any
): Array<any> => {
  return [...array, ...newArray];
};

/**
 * @param {boolean} loading Is the function loading?
 */
export const taskLoading = (loading: string | boolean): TaskState => ({
  loading: loading,
  empty: false,
  error: false,
  success: false,
});

/**
 * @param {boolean} error What was the error?
 */
export const taskErrored = (error: string | boolean): TaskState => ({
  loading: false,
  empty: false,
  error: error?.toString(),
  success: false,
});

/**
 * @param {string} success What was the success message?
 */
export const taskSucceeded = (success: string | boolean): TaskState => ({
  loading: false,
  empty: false,
  error: false,
  success: success,
});

/**
 * @param {boolean} empty Was the snapshot empty?
 */
export const taskResultsEmpty = (empty: string | boolean): TaskState => ({
  loading: false,
  empty: empty,
  error: false,
  success: false,
});

export const taskCleared = () => ({
  loading: false,
  empty: false,
  error: false,
  success: false,
});

/**
 *Clear a populated array
 */
export const taskClearArray = () => [];

/**
 * @param {Object} INITIAL_STATE reset to initial state
 */
export const resetToInitialState = (INITIAL_STATE: TaskState) => ({
  ...INITIAL_STATE,
});

/**
 * @param {string} taskConstant constant for the reducer(task) action
 * @param {Object} INITIAL_STATE reset to initial state
 */
export const createReducerWithTaskName = (
  taskConstant = "",
  INITIAL_STATE: TaskState
) => {
  return function tasks(state = INITIAL_STATE, action: any): TaskState {
    switch (action.type) {
      case `${taskConstant}_LOADING`: {
        return {
          ...taskLoading(action.value),
        };
      }
      case `${taskConstant}_EMPTY`: {
        return {
          ...taskResultsEmpty(action.value),
        };
      }
      case `${taskConstant}_SUCCESS`: {
        return {
          ...taskSucceeded(action.value),
        };
      }
      case `${taskConstant}_ERROR`: {
        return {
          ...taskErrored(action.value),
        };
      }
      case `${taskConstant}_CLEAR`: {
        return {
          ...taskCleared(),
        };
      }
      default:
        return state;
    }
  };
};

export type TaskStateConstant =
  | "LOADING"
  | "EMPTY"
  | "SUCCESS"
  | "ERROR"
  | "CLEAR";
/**
 * @param {string} taskConstant constant for the reducer(task) action
 * @param {TaskStateConstant} taskState state of the task, ie LOADING, ERROR, SUCCESS, EMPTY
 * @param {any} value the new value for the state of the task
 */
export const dispatchTaskWithState = (
  taskConstant: string,
  taskState: TaskStateConstant,
  value: any
) => ({
  type: `${taskConstant}_${taskState}`,
  value,
});

export const createReducerForKeyedArray = (
  arrayConstant = "",
  INITIAL_STATE: Array<any>
) => {
  return function array(
    state = INITIAL_STATE ? [...INITIAL_STATE] : [],
    action: any
  ): Array<any> {
    switch (action.type) {
      case `ADD_${arrayConstant}`: {
        return [...insertItemWithKey(state, action.object)];
      }
      case `ADD_REVERSE_${arrayConstant}`: {
        return [...insertItemWithKeyBottom(state, action.object)];
      }
      case `ADD_ARRAY_${arrayConstant}`: {
        return [...insertArray(state, action.object)];
      }
      case `ADD_ARRAY_REVERSE_${arrayConstant}`: {
        return [...insertArrayBottom(state, action.object)];
      }
      case `REMOVE_${arrayConstant}`: {
        return [...removeItemWithKey(state, action.object)];
      }
      case `UPDATE_${arrayConstant}`: {
        return [...updateItemWithKey(state, action.object)];
      }
      case `CLEAR_${arrayConstant}`: {
        return [...taskClearArray()];
      }
      default:
        return state;
    }
  };
};

export type KeyedArrayConstant =
  | "ADD"
  | "ADD_REVERSE"
  | "ADD_ARRAY"
  | "ADD_ARRAY_REVERSE"
  | "REMOVE"
  | "UPDATE"
  | "CLEAR";

/**
 * @param {string} arrayConstant constant for the reducer(array) action
 * @param {KeyedArrayConstant} arrayState state of the task, ie ADD, ADD_REVERSE, REMOVE, ADD_ARRAY, ADD_ARRAY_REVERSE, UPDATE, CLEAR
 * @param {array} array the array to be updated
 * @param {Object} object the object for the update
 */
export const dispatchKeyedArrayWithState = (
  arrayConstant: string,
  arrayState: KeyedArrayConstant,
  object: any | Array<any>
) => ({
  type: `${arrayState}_${arrayConstant}`,
  object,
});

export const createReducerForArray = (
  arrayConstant = "",
  INITIAL_STATE: Array<any>
) => {
  return function array(
    state = INITIAL_STATE ? [...INITIAL_STATE] : [],
    action: any
  ): Array<any> {
    switch (action.type) {
      case `ADD_${arrayConstant}`: {
        return [...insertItem(state, action.item, action.index)];
      }
      case `REMOVE_${arrayConstant}`: {
        return [...removeItem(state, action.index)];
      }
      case `UPDATE_${arrayConstant}`: {
        return [...updateObjectInArray(state, action.item, action.index)];
      }
      case `CLEAR_${arrayConstant}`: {
        return [...taskClearArray()];
      }
      case `RESET_${arrayConstant}`: {
        return [...INITIAL_STATE];
      }
      default:
        return state;
    }
  };
};

export type ArrayConstant = "ADD" | "REMOVE" | "UPDATE" | "CLEAR" | "RESET";

/**
 * @param {string} arrayConstant constant for the reducer(array) action
 * @param {ArrayConstant} arrayState state of the task, ie ADD, ADD_REVERSE, REMOVE, ADD_ARRAY, ADD_ARRAY_REVERSE UPDATE, CLEAR
 * @param {array} array the array to be updated
 * @param {item} item the new object of the array at index for the update
 * @param {index} index the index of the array to be updated
 */
export const dispatchArrayWithState = (
  arrayConstant: ArrayConstant,
  arrayState: string,
  item: object,
  index: number
) => ({
  type: `${arrayState}_${arrayConstant}`,
  item,
  index,
});

/**
 * @param {string} objectConstant constant for the reducer(array) action
 * @param {any} INITIAL_STATE the initial state of the object
 */
export const createSimpleReducer = (
  objectConstant = "",
  INITIAL_STATE: any
) => {
  return function tasks(state = INITIAL_STATE, action: any): any {
    switch (action.type) {
      case `SET_${objectConstant}`: {
        return {
          ...state,
          ...action.object,
        };
      }
      case `SET_ARRAY_${objectConstant}`: {
        return [...action.object];
      }
      case `RESET_${objectConstant}`: {
        return {
          ...INITIAL_STATE,
        };
      }
      case `RESET_ARRAY_${objectConstant}`: {
        return [...INITIAL_STATE];
      }
      default:
        return state;
    }
  };
};

export type SimpleConstant = "SET" | "SET_ARRAY" | "RESET" | "RESET_ARRAY";
/**
 * @param {string} objectConstant constant for the reducer(array) action
 * @param {SimpleConstant} objectState state of the task, ie SET, RESET
 * @param {any} object the object to be updated
 */
export const dispatchSimpleReducerWithState = (
  objectConstant: string,
  objectState: SimpleConstant,
  object: any
) => ({
  type: `${objectState}_${objectConstant}`,
  object,
});
