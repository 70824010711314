import React from "react";
import { SvgIcon } from "@material-ui/core";

const LinkedInIcon = (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="67px"
      id="Layer_1"
      style={{ enableBackground: "new 0 0 67 67" }}
      version="1.1"
      viewBox="0 0 67 67"
      width="67px"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M50.837,48.137V36.425c0-6.275-3.35-9.195-7.816-9.195  c-3.604,0-5.219,1.983-6.119,3.374V27.71h-6.79c0.09,1.917,0,20.427,0,20.427h6.79V36.729c0-0.609,0.044-1.219,0.224-1.655  c0.49-1.22,1.607-2.483,3.482-2.483c2.458,0,3.44,1.873,3.44,4.618v10.929H50.837z M22.959,24.922c2.367,0,3.842-1.57,3.842-3.531  c-0.044-2.003-1.475-3.528-3.797-3.528s-3.841,1.524-3.841,3.528c0,1.961,1.474,3.531,3.753,3.531H22.959z M34,64  C17.432,64,4,50.568,4,34C4,17.431,17.432,4,34,4s30,13.431,30,30C64,50.568,50.568,64,34,64z M26.354,48.137V27.71h-6.789v20.427  H26.354z"
        style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "white" }}
      />
    </SvgIcon>
  );
};

export default LinkedInIcon;
