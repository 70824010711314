import React, { useState, useEffect, useRef } from "react";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import { PwgAlert } from "../models/common";
import { useDispatch } from "react-redux";
import { rSetAlert } from "../reducers/session/actions";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface Props {}
const AlertCenter: React.FC<Props> = () => {
  const queueRef = useRef<PwgAlert[]>([]);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = React.useState<PwgAlert | undefined>(
    undefined
  );
  const alert: PwgAlert = useSelector((state: any) => state.sessionState.alert);

  useEffect(() => {
    if (alert.key) {
      queueRef.current.push({
        message: alert.message,
        key: alert.key,
        type: alert.type,
        duration: alert.duration,
        icon: alert.icon,
        action: alert.action,
      });
    }
    processQueue();
  }, [alert]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    dispatch(rSetAlert({ key: "" } as PwgAlert));
  };

  const processQueue = () => {
    if (queueRef.current.length > 0) {
      setMessageInfo(queueRef.current.shift());
      setOpen(true);
    }
  };

  const handleExited = () => {
    processQueue();
  };

  const getIcon = (icon?: string) => {
    if (!icon) {
      return null;
    } else if (icon === "upload") {
      return <PublishIcon />;
    } else if (icon === "download") {
      return <GetAppIcon />;
    } else {
      return null;
    }
  };
  const getAlertDuration = () => {
    if (alert.duration) {
      return alert.duration;
    } else if (alert.duration === 0) {
      return null;
    } else {
      return 6000;
    }
  };

  return (
    <Snackbar
      key={messageInfo ? messageInfo.key : undefined}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={getAlertDuration()}
      onClose={handleClose}
      onExited={handleExited}
      message={messageInfo ? messageInfo.message : undefined}
    >
      <Alert
        onClose={alert.action ? undefined : handleClose}
        severity={messageInfo?.type}
        icon={getIcon(messageInfo?.type)}
      >
        {messageInfo?.message}
      </Alert>
    </Snackbar>
  );
};

export default AlertCenter;
