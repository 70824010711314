import React from "react";
import AlertCenter from "./AlertCenter";
import { layoutStyles } from "./styles";
import PrepFqHeader from "./PrepFqHeader";
import { useRouter } from "next/router";
import {
  PREP_FQ_PDF,
  PREP_FQ_PDF_VIEWER,
  PREMIUM_DASHBOARD,
  JOURNAL_ENTRIES,
  PREP_FQ_INTRO,
} from "../constants/routes";
import Header from "./Header";
import Footer from "./Footer";

const PrepFqLayout = ({ children }) => {
  const classes = layoutStyles();
  const router = useRouter();
  const isPdf = router.pathname === PREP_FQ_PDF;
  const isPdfViewer = router.pathname === PREP_FQ_PDF_VIEWER;
  const isReport = router.pathname === PREMIUM_DASHBOARD;
  const isJournalEntries = router.pathname.includes(JOURNAL_ENTRIES);
  const isIntro = router.pathname.includes(PREP_FQ_INTRO);
  const isForm =
    !isPdf && !isPdfViewer && !isReport && !isJournalEntries && !isIntro;
  const showDefaultLayout =
    isReport || isPdfViewer || isJournalEntries || isIntro;
  return (
    <div className={classes.root}>
      {isForm && !showDefaultLayout && <PrepFqHeader />}
      {showDefaultLayout && <Header />}
      <main className={classes.root} style={{ marginBottom: isForm ? 100 : 0 }}>
        {children}
        <AlertCenter />
      </main>
      {showDefaultLayout && <Footer />}
    </div>
  );
};

export default PrepFqLayout;
