import React from 'react';
import AlertCenter from './AlertCenter';
import Footer from './Footer';
import Header from './Header';
import Main from './Main';
import { layoutStyles } from './styles';

const DashLayout = ({ children }) => {
  const classes = layoutStyles();
  return (
    <div className={classes.root}>
      <Header />
      <Main>
        {children}
        <AlertCenter />
      </Main>
      <Footer />
    </div>
  )
}

export default DashLayout;