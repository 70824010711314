import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// pale grey #CCD9DD 100%
// pale grey #E6ECEE 50%
// pale grey #F2F6F7 25%
// purple #3E4066
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#002655", // navy blue
    },
    secondary: {
      main: "#5db2e3", // blue
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      "Helvetica",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h2: {
      fontStyle: "italic",
      fontSize: "40px",
      fontWeight: 800,
      color: "#002655",
      "@media (max-width: 1000px)": {
        fontSize: "24px",
      },
    },
    h3: {
      fontStyle: "italic",
      fontSize: "20px",
      fontWeight: 800,
      color: "#5db2e3",
    },
    body1: {
      fontSize: "14px",
      color: "#002655",
    },
    body2: {
      fontSize: "14px",
      color: "#002655",
    },
  },
  overrides: {
    MuiButton: {
      textPrimary: {
        padding: "6px 24px",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "24px",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#5db2e3",
        },
      },
      outlinedPrimary: {
        padding: "6px 24px",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "24px",
        fontStyle: "italic",
      },
      contained: {
        "&$disabled": {
          backgroundColor: "#fff",
          color: "#002655",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
      },
      containedPrimary: {
        borderRadius: "24px",
        padding: "6px 24px",
        color: "#F2F6F7",
        textTransform: "none",
        fontStyle: "italic",
        transition: "all 0.3s ease-out",
        fontSize: "16px",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#002655",
        },
      },
      containedSecondary: {
        borderRadius: "24px",
        padding: "6px 24px",
        color: "#F2F6F7",
        textTransform: "none",
        fontStyle: "italic",
        transition: "all 0.3s ease-out",
        "&:hover": {
          backgroundColor: "#fff",
          color: "#002655",
        },
      },
    },
    MuiLink: {
      root: {
        fontSize: "14px",
      },
      underlineHover: {
        textDecoration: "underline",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#002655",
        padding: "0px 6px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "24px",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#5db2e3",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#5db2e3",
        },
      },
      input: {
        padding: "18.5px 24px",
      },
      notchedOutline: {
        borderColor: "#5db2e3",
      },
    },
    MuiSelect: {
      outlined: {
        borderRadius: "24px !important",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        backgroundColor: "rgb(66,66,66)",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "24px",
        padding: "24px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "16px",
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
    MuiTab: {
      root: {
        fontSize: "18px",
        textTransform: "capitalize",
        marginTop: "8px",
        marginBottom: "8px",
      },
      wrapper: {
        alignItems: "flex-end",
        paddingEnd: "12px",
        "@media (max-width: 960px)": {
          alignItems: "center",
          paddingEnd: "0",
        },
      },
    },
  },
});

export default theme;
