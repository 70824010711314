import React from 'react';
import { bodyStyles } from './styles';

interface Props {
  style?: React.CSSProperties;
}

const Main: React.FC<Props> = ({ style, children }) => {
  const classes = bodyStyles();
  return (
    <main className={classes.root} style={style}>{children}</main>
  )
}

export default Main;