import React from "react";
import { SvgIcon } from "@material-ui/core";

const InstagramIcon = (props) => {
  return (
    <SvgIcon data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200"  {...props}>
      <path className="instagram-1" d="M174.9954,34.7815C161.0907,18.409,143.4066,7.8275,122.4439,2.8232A97.3287,97.3287,0,0,0,90.1121.6314,97.7738,97.7738,0,0,0,30.8831,27.6,97.259,97.259,0,0,0,2.9981,75.4766,94.9684,94.9684,0,0,0,.3275,104.94a96.9409,96.9409,0,0,0,24.2251,59.0169,97.7629,97.7629,0,0,0,55.116,32.048c4.4779.9224,9.093,1.1945,13.6466,1.784A7.5355,7.5355,0,0,1,94.3171,198h10.2463c1.3809-.15,2.7314-.3322,4.113-.4382a99.2653,99.2653,0,0,0,82.6205-60.4984,93.93,93.93,0,0,0,6.8912-26.5455C201.21,82.0067,193.5589,56.6558,174.9954,34.7815Z" />
      <path className="instagram-2" d="M127.9994,99.1044c.3033,13.9835-12.8568,28.36-28.3856,28.4052-17.6225.03-28.5058-16.8552-28.4754-28.4052.0453-14.845,13.373-28.9042,28.643-28.6617C114.9758,70.67,127.9994,83.7307,127.9994,99.1044Zm-28.46,18.6392c9.7145.6206,19.0041-8.3438,18.8676-18.5932-.1216-10.9-8.06-19.0329-18.5495-19.245A18.7922,18.7922,0,0,0,80.7921,99.1348C80.7009,109.4,89.9148,118.3034,99.5389,117.7436Z" />
      <path className="instagram-2" d="M94.8333,154.4184c-6.5122-.1512-13.0088-.2269-19.5062-.4538-17.5616-.65-27.99-12.6377-30.2819-25.5178a84.1808,84.1808,0,0,1-1.108-12.8342c-.2129-9.1913-.2581-18.413-.2277-27.6044.0452-5.0182.3789-10.0373.683-15.0415.85-13.348,9.5929-23.6278,19.7176-27.241A40.942,40.942,0,0,1,78.014,43.6851c13.525-.0452,27.05-.2113,40.59-.1356a55.0016,55.0016,0,0,1,13.6911,1.2545,27.7463,27.7463,0,0,1,13.3729,7.71,33.11,33.11,0,0,1,7.0892,10.1285,35.9343,35.9343,0,0,1,2.3836,13.5453c.1513,9.4479.44,18.8957.425,28.3139-.03,7.196-.2433,14.3765-.7291,21.5421a28.5159,28.5159,0,0,1-7.6654,17.4594c-4.8124,5.3372-10.61,8.95-17.76,9.8572a136.7722,136.7722,0,0,1-14.7242.8312c-6.6183.1505-13.2358.0445-19.854.0445ZM146.23,98.8775c-.1669-6.8779-.3189-15.7363-.683-24.5806a22.4349,22.4349,0,0,0-3.0511-10.8233c-4.2954-7.06-10.8989-10.1589-18.8676-10.4763-8.09-.3173-16.1807-.3781-24.2867-.4086-5.0245-.0148-10.049.2121-15.0735.3634-4.0982.1364-8.212,0-12.219.5294A19.6144,19.6144,0,0,0,55.9137,65.3629c-1.9891,4.3087-2.2924,8.8435-2.4141,13.4088-.2269,9.3426-.38,18.7008-.3033,28.073.0764,6.3492.3493,12.7133,1.002,19.0165a20.4467,20.4467,0,0,0,17.1982,18.0809A106.3365,106.3365,0,0,0,86.4388,145.06q14.276.25,28.5526-.0756a84.2315,84.2315,0,0,0,13.9343-1.285A20.2022,20.2022,0,0,0,145.5626,124.41C145.8815,116.5654,145.9867,108.7035,146.23,98.8775Z" />
      <path className="instagram-2" d="M134.921,70.11c0,4.2331-2.0944,6.304-6.375,6.2892-4.1138-.0148-6.269-2.2074-6.2541-6.3492.0156-4.1879,1.9734-6.0779,6.2541-6.092C133.0691,63.9578,134.921,65.7418,134.921,70.11Z" />
    </SvgIcon >
  )
}

export default InstagramIcon;