import React from "react";
import { SvgIcon } from "@material-ui/core";

const PrepFqIcon = (props) => {
  return (
    <SvgIcon
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 357.4 357.4"
      style={{ enableBackground: "new 0 0 357.4 357.4" }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g id="Layer_1_00000013163628431416221320000016040367601188760487_">
          <path
            d="M229.3,72.1c22.3-3.5,41.1,13.6,41.3,37c0.2,27.5,0,54.9,0,82.4c0,27,0,54.1,0,81
			c-0.2,23.1-15.5,38.2-38.6,38.2c-41.9,0-83.6,0-125.4,0c-23.1,0-38.6-15.1-38.6-38.2c-0.2-54.3-0.2-108.4,0-162.7
			c0-24,18.6-41.1,40.9-37.8v16.3c-1.9,0.2-3.7,0.6-5.4,0.8c-11.6,1-18.4,7.9-18.6,19.8c-0.2,18.2-0.2,36.6-0.2,54.9
			c0,35.3,0,70.4,0,105.7c0,16.7,6.8,23.7,23.5,23.7c40.9,0,81.6,0,122.5,0c16.3,0,23.5-7,23.5-22.9c0-52.7,0-105.7,0-158.4
			c0-14.9-5.2-20.7-20-22.5c-3.1-0.4-4.7-1.4-4.5-4.7C229.3,80.2,229.3,75.9,229.3,72.1z"
          />
          <path
            d="M141.1,61.6c0.4-1.4,1-2.9,1.2-4.5c2.7-13.6,13.2-22.5,26.6-22.7c13.6-0.6,25.2,9.3,27.1,22.5
			c0.8,4.1,2.3,5.2,6.4,5.4c10.7,0.2,14.3,4.7,14.7,15.3c0.8,20.2-3.1,23.8-23.3,23.3c-18.8-0.6-37.6-0.2-56.4-0.2
			c-12,0-16.1-4.3-16.3-15.9C121.3,64.9,121.9,64.3,141.1,61.6z M183,61.8c0-8.5-5.8-14.5-13.8-14.5C162,47,156,52.9,155.8,60
			c0,0.6,0,1.2,0,1.6H183V61.8z"
          />
          <path d="M177.3,198.9h-71.5c-0.4-0.6-0.6-1.4-0.8-2.1c-0.8-11.2-0.8-11.2,10.3-11.2h62V198.9z" />
          <path d="M105.2,251.8v-12.6h72.1v12.6H105.2z" />
          <path
            d="M212.4,237.8c3.3-3.5,5.8-6.6,8.7-9.5c2.7-2.5,5.8-3.3,8.5-0.2c2.3,2.1,2.3,5.8,0.2,8.1
			c-0.2,0.2-0.4,0.4-0.6,0.6c-2.5,2.5-5.2,4.7-9.7,8.5c4.1,3.1,7.2,5.4,10.1,8.1c2.7,2.5,3.1,5.8,0.2,8.7s-6,2.1-8.7-0.4
			c-2.7-2.5-5.4-5-9.1-8.5c-2.7,3.1-5,6-7.8,8.5c-2.9,2.9-6.2,3.7-9.3,0.6c-2.9-2.9-1.9-6.2,0.6-8.7c2.5-2.5,5.4-4.5,9.5-7.8
			c-4.1-3.5-7-5.8-9.7-8.3c-2.9-2.7-3.9-6-0.6-9.1c3.3-3.1,6.4-1.7,9.1,1C206.8,232,209.3,234.5,212.4,237.8z"
          />
          <path
            d="M212.2,134.1c3.5-4.1,6-7.4,8.7-10.1c2.5-2.7,5.8-3.5,8.7-0.6c2.7,2.9,2.3,6-0.2,8.7c-2.3,2.5-4.8,5-7.8,8.1
			c2.7,2.9,5.2,5.4,7.8,8.1c2.7,2.9,3.7,6.2,0.4,9.3c-2.9,2.9-6,1.9-8.7-0.6c-2.5-2.3-5.2-4.8-8.3-7.8c-2.7,2.5-5.4,5-7.9,7.8
			c-2.9,2.9-6,4.1-9.3,1.2c-3.3-3.1-2.3-6.4,0.6-9.1c2.7-2.3,5.4-4.3,8.5-6.2c-0.2-1-0.2-1.9-0.4-2.7c-2.9-2.3-6-4.5-8.7-7.2
			c-2.9-2.9-3.5-6.2-0.2-9.3c3.1-2.9,6.2-1.7,8.5,1C206.6,127.3,208.9,130.2,212.2,134.1z"
          />
          <path d="M177.7,134.7c0,2.9,0,5.2,0,7.6c0,1.4-0.2,2.7-0.2,4.7h-72.3v-12.2L177.7,134.7L177.7,134.7z" />
          <path
            d="M203.9,195.2c10.3-7.2,19-16.3,30.6-21.1c0.6,0.6,1.2,1,1.7,1.6c-0.6,2.1-1.7,4.3-2.9,6.2
			c-7.8,8.7-15.7,17.4-23.7,25.8c-4.7,4.8-8.5,4.5-12.2-1c-2.7-4.3-5.2-8.7-7.4-13.6c-0.6-1.4,0.2-3.3,0.2-5c1.7,0,3.5,0.2,5,0.8
			C198.3,190.7,201.2,192.8,203.9,195.2z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PrepFqIcon;
