import {
  SET_PAGE,
  SET_AUTH_USER,
  SET_ALERT,
} from "./constants";

export const SESSION_INITIAL_STATE = {
  authUser: null,
  page: "/",
  alert: {
    type: "",
    message: "",
    duration: null,
  },
};

function sessionReducer(state = SESSION_INITIAL_STATE, action: any) {
  switch (action.type) {
    case SET_AUTH_USER: {
      return {
        ...state,
        authUser: {
          ...action.authUser,
        },
      };
    }
    case SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    case SET_ALERT: {
      return {
        ...state,
        alert: action.alert,
      };
    }
    default:
      return state;
  }
}

export default sessionReducer;
