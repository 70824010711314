import { Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { DASHBOARD } from "../constants/routes";
import { headerStyles } from "./styles";

const PrepFqHeader = () => {
  const { t } = useTranslation(["common", "header"]);
  const classes = headerStyles();
  return (
    <header className={classes.prepFqHeader}>
      <Link passHref href={DASHBOARD}>
        <div className={classes.authHeaderWordMark}>
          <div className={classes.authHeaderLogoContainer}>
            <Image
              src="/logo.svg"
              alt={t("common:title")}
              layout="fixed"
              height="80"
              width="107.5"
            />
          </div>
          <Typography variant="h2" className={classes.authHeaderTitle}>
            {`${t(`common:planWell`)} `}
            <span className={classes.blue}>{`${t(`common:guide`)}`}</span>
          </Typography>
        </div>
      </Link>
      <Typography variant="h2" className={classes.prepFqTitle}>{`${t(
        `header:titles.prepFqQuestionnaire`
      )}`}</Typography>
    </header>
  );
};

export default PrepFqHeader;
