import { Button, Grid } from "@material-ui/core";
import React from "react";
import { footerStyles } from "./styles/prodStyles";
import { useTranslation } from "next-i18next";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import FacebookIcon from "./icons/FacebookIcon";
import InstagramIcon from "./icons/InstagramIcon";
import TwitterIcon from "./icons/TwitterIcon";
import LinkedInIcon from "./icons/LinkedInIcon";
import Link from "next/link";
import { PRIVACY_POLICY, TERMS_OF_SERVICE } from "../constants/routes";

const AltFooter = () => {
  const { t } = useTranslation(["footer", "common"]);
  const classes = footerStyles();
  return (
    <footer className={classes.altRoot}>
      <div className={classes.content}>
        <Grid container justify="center" alignItems="center">
          <Grid xs={12} item>
            <div className={classes.socialMediaContents}>
              <ul className={clsx(classes.socialsList, classes.socialsListAlt)}>
                <IconButton
                  className={classes.socialIcon}
                  aria-label="facebook"
                  href="https://www.facebook.com/Plan-Well-Guide-1993806247413539/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <FacebookIcon className={classes.socialIcon} />
                </IconButton>
                <IconButton
                  aria-label="instagram"
                  href="https://www.instagram.com/plan_well_guide/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <InstagramIcon className={classes.socialIcon} />
                </IconButton>
                <IconButton
                  aria-label="twitter"
                  href="https://twitter.com/plan_well_guide"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <TwitterIcon className={classes.socialIcon} />
                </IconButton>
                <IconButton
                  aria-label="twitter"
                  href="https://www.linkedin.com/in/daren-heyland-2b674a185/?originalSubdomain=ca"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <LinkedInIcon className={classes.linkedInIcon} />
                </IconButton>
              </ul>
            </div>
          </Grid>
        </Grid>
        <Grid
          className={clsx(classes.linksContents, classes.linksContentsAlt)}
          container
        >
          <Grid
            className={clsx(classes.linkContainer, classes.linkContainerAlt)}
            item
            lg={"auto"}
            md={"auto"}
            sm={"auto"}
            xs={12}
          >
            <Link passHref href={PRIVACY_POLICY}>
              <Button variant="text" className={classes.link}>
                {t("footer:links.pp")}
              </Button>
            </Link>
          </Grid>
          <Grid
            className={clsx(classes.linkContainer, classes.linkContainerAlt)}
            item
            lg={"auto"}
            md={"auto"}
            sm={"auto"}
            xs={12}
          >
            <Link passHref href={TERMS_OF_SERVICE}>
              <Button variant="text" className={classes.link}>
                {t("footer:links.tos")}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <div className={clsx(classes.divider, classes.dividerAlt)} />
        <div className={classes.copyrightContainer}>
          <p className={classes.copyRight}>{t("footer:copyright")}</p>
        </div>
      </div>
    </footer>
  );
};

export default AltFooter;
